.s_characteristics {
  padding: 110px 0 70px;
  @include r(1499) {
    padding: 96px 0 36px; }
  @include r(1023) {
    padding: 64px 0; }
  @include r(767) {
    padding: 64px 0 36px; }
  * {
    color: $white; }

  &__title {
    margin-bottom: 36px;
    position: relative;
    @include r(1023) {
      margin-bottom: 24px; }
    h3 {
      z-index: 3;
      position: relative; }
    &::after {
      position: absolute;
      background: url(../images/quotes.png) no-repeat center center;
      background-size: cover;
      content: '';
      left: 0px;
      top: -45px;
      width: 105px;
      height: 90px;
      z-index: 1;
      @include r(767) {
        width: 73px;
        height: 62px;
        top: -31px; } } }

  &__text {
    max-width: 1220px;
    margin: auto;
    width: 100%;
    text-align: center;
    margin-bottom: 74px; }

  &__items {
    margin: -26px;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    @include r(1023) {
      margin: -36px; }
    @include r(767) {
      margin: 0px; } }
  &__item {
    text-align: center;
    max-width: 33.3333%;
    width: 100%;
    border-right: 1px solid #2F6CB4;
    padding: 26px;
    &:nth-child(3n+3) {
      border: none; }
    @include r(1023) {
      max-width: 50%;
      padding: 36px;
      &:nth-child(3n+3) {
        border-right: 1px solid #2F6CB4; }
      &:nth-child(2n+2) {
        border: none; } }
    @include r(767) {
      max-width: 100%;
      border: none;
      padding: 0; } }
  &__img {
    min-height: 85px;
    max-width: 75px;
    width: 100%;
    margin: auto;
    img, svg {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      display: block; } }
  &__name {
    margin-bottom: 24px;
    p {
      color: $blue;
      font-size: 18px;
      @include r(767) {
        font-size: 16px; } } }

  &__list {
    ul {
      li {
        padding-left: 16px;
        font-size: 18px;
        text-align: left;
        @include r(767) {
          font-size: 16px; }
        @include r(767) {
          font-size: 14px; }
        &::after {
          background: white;
          top: 0.5em; } } } }

  .slick-arrow {
    position: absolute;
    border: none;
    cursor: pointer;
    outline: none;
    border: 2px solid white;
    border-width: 4px 0 0 4px;
    padding: 6px;
    font-size: 0;
    background: transparent;
    z-index: 10;
    transition: all 0.2s ease-in-out;
    @include r(1023) {
      border-width: 3px 0 0 3px;
      padding: 4px; }
    &:hover {
      border-color: $blue; } }
  .slick-arrow.slick-next {
    right: 6px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg); }
  .slick-arrow.slick-prev {
    left: 6px;
    top: 50%;
    transform: translateY(-50%) rotate(315deg); } }

