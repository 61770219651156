.s_header {
  background-color: $dark-blue;
  padding: 22px 0 17px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 10;
  .custom-line {
    position: absolute;
    background: $blue;
    height: 2px;
    width: 0px;
    transition: all 0.2s ease-in-out;
    bottom: 0;
    left: 19px; }
  .container {
    @include r(1023) {
      padding: 0 25px; }
    @include r(767) {
      padding: 0 16px; } }
  @include r(1199) {
    &::after {
      position: absolute;
      left: 0;
      z-index: 4;
      background: $dark-blue;
      top: 0;
      width: 100%;
      height: 100%;
      content: ''; } }
  @include r(767) {
    padding: 24px 0 11px; }
  &_wrap {
    align-items: center; }
  &_logo {
    display: block;
    position: relative;
    z-index: 5;
    @include r(767) {
      max-width: 184px; }
    img,
    svg {
      display: block;
      max-width: 100%;
      height: auto; } }
  &_menu {
    @include r(1199) {
      position: fixed;
      z-index: 2;
      left: 0%;
      top: -100%;
      width: 100%;
      height: 100%;
      background: $dark-blue;
      overflow-y: auto;
      transition: all 0.2s ease-in-out;
      &--open {
        top: 0; } }
    &_wrap {
      @include r(1199) {
        min-height: 100vh;
        padding: 110px 0;
        overflow-y: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
      ul {
        position: relative;
        justify-content: flex-start;
        @include r(1199) {
          display: block; }
        li {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 20px;
          font-weight: 700;
          margin: 0;
          margin-left: 24px;
          color: #fff;
          letter-spacing: 0.06em;

          transition: all 0.2s ease-in-out;
          @include r(1199) {
            text-align: center;
            margin: 0;
            padding: 0;
            margin-bottom: 40px; }

          &:last-of-type {
            padding-right: 0;
            margin-right: 0;
            a {
              padding-right: 0;
              &::after {
                display: none; } }
            &::after {
              display: none; } }
          &:hover {
 }            // color: $blue
          a {
            font-style: inherit;
            font-family: inherit;
            color: inherit;
            font-weight: inherit;
            position: relative;
            padding-right: 28px;
            position: relative;
            @include r(1199) {
              padding: 0; }
            &::after {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              content: '';
              width: 1px;
              height: 24px;
              background: $blue;
              @include r(1199) {
                display: none; } } } }
        li.active {
          color: $blue;
          a {
            // &::after
 } } } } }            //   width: 110%
  &_burger {
    display: none;
    @include r(1199) {
      display: block;
      flex-shrink: 0;
      cursor: pointer;
      width: 40px;
      height: 23px;
      flex-shrink: 0;
      position: relative;
      z-index: 5;
      &_line {
        position: absolute;
        left: 0;
        width: 100%;
        background: #fff;
        height: 3px;
        transition: all 0.2s ease-in-out;
        &--one {
          top: 0; }
        &--two {
          top: 10px; }
        &--three {
          bottom: 0; } }
      &--open {
        .s_header_burger_line--one {
          top: 50%;
          transform: translateX(-50%) rotate(45deg);
          width: 75%;
          left: 50%; }
        .s_header_burger_line--two {
          opacity: 0;
          transition: all 0.1s ease-in-out; }
        .s_header_burger_line--three {
          top: 50%;
          left: 50%;
          width: 75%;
          transform: translateX(-50%) rotate(-45deg); } } } } }
