$white: #fff;
$black: #000;
$dark: #333333;
$blue: #27AAE1;
$dark-blue: #213C61;

$grad: linear-gradient(88.15deg, #182E4C -2.21%, #1B3C63 25.67%, #20436D 68.27%, #142742 122.52%);




// $grad-2: linear-gradient(91.9deg, #44C5F8 0.26%, #00ECC3 100.34%), #44C5F8
// $button-grad:  linear-gradient(to right, rgba(68,197,248,1) 0%, rgba(221,163,217,1) 0%, rgba(0,236,197,1) 0%, rgba(68,197,248,1) 40%, rgba(68,197,248,1) 60%, rgba(0,236,197,1) 100%)
