.s_hero {
  overflow: hidden;

  &__slider {
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 0;
    transition: 0.5s ease;
    height: 100%;
    @include r(1499) {
      background-size: cover; } }


  &__slide-wr {
    position: relative;
    padding: 60px 0;
    @include r(767) {
      padding: 24px 16px; } }
  &__slide {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    @include r(767) {
      flex-wrap: wrap;
      flex-direction: column-reverse; } }


  &__content {
    max-width: 68%;
    width: 100%;
    @include r(1499) {
      max-width: 60%;
      padding-right: 24px;
      padding-left: 36px; }
    @include r(1023) {
      max-width: 50%;
      padding-right: 10px; }
    @include r(767) {
      max-width: 100%;
      padding: 0; } }
  &__title {
    margin-bottom: 36px;
    @include r(1023) {
     margin-bottom: 12px; } }
  &__img {
    position: relative;
    max-width: 420px;
    width: 100%;
    @include r(1023) {
      max-width: 350px; }
    @include r(767) {
      max-width: 50%; }
    img {
      width: 100%;
      display: block;
      object-fit: cover;
      z-index: 2;
      position: relative; } }

  &__text {
    margin-bottom: 76px;
    max-width: 800px;
    width: 100%;
    min-height: 170px;
    @include r(1499) {
      margin-bottom: 40px; }
    @include r(1023) {
     margin-bottom: 36px; }
    p {
      color: #D1F1FC;
      opacity: 0.8;
      font-size: 24px;
      line-height: 45px;
      font-weight: 300;
      @include r(1499) {
        font-size: 18px;
        line-height: 27px; } } }
  &__items {
    align-items: center;
    @include r(1499) {
      flex-wrap: wrap;
      justify-content: flex-start; }
    @include r(767) {
      align-items: flex-start; } }
  &__item {
    align-items: center;
    border-right: 1px solid white;
    margin-right: 30px;
    padding-right: 30px;
    @include r(1499) {
      max-width: 47%;
      width: 100%;
      margin-right: 24px;
      padding-right: 24px;
      justify-content: flex-start;
      margin-bottom: 24px;
      &:nth-of-type(2n) {
        margin-right: 0px;
        padding-right: 0px;
        border: none; } }
    @include r(1023) {
      max-width: 100%;
      border: none; }
    @include r(767) {
      margin: 0 -12px;
      flex-direction: column;
      justify-content: flex-start; }

    &:last-of-type {
      border: none;
      margin-right: 0px;
      padding-right: 0px; }
    @include r(767) {
      max-width: 50%;
      width: 100%;
      margin-right: 16px;
      padding-right: 0;
      padding: 0 12px;
      justify-content: flex-start;
      margin-bottom: 24px;
      border-right: 1px solid white;
      &:nth-of-type(2n) {
        margin-right: 0px;
        padding-right: 0px;
        border: none;
        max-width: 50%; }
      &:last-of-type {
        order: 3; } }
    &-img {
      margin-right: 16px;
      @include r(1023) {
        svg {
          max-width: 60px; } }
      @include r(767) {
        margin-right: 0px;
        margin-bottom: 6px;
        svg {
          height: auto; } } }


    &-name {
      max-width: 180px;
      @include r(1499) {
        max-width: 100%;
        text-align: left; }
      p {
        color: $white;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.2;
        @include r(767) {
          font-size: 12px;
          text-align: center; } } } }

  .slick-arrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
    display: inline-block;
    border: solid $white;
    border-width: 0 4px 4px 0;
    padding: 6px;
    font-size: 0;
    outline: none;
    z-index: 10;
    background: transparent;
    opacity: 0.5;
    &:hover {
      opacity: 1; }
    @include r(1499) {
      border-width: 0 3px 3px 0; }
    @include r(999) {
      padding: 4px; } }
  .slick-arrow.slick-next {
    right: 100px;
    transform: translateY(-50%) rotate(-45deg);
    @include r(1499) {
      right: 10px; }
    @include r(767) {
      right: 26px; } }

  .slick-arrow.slick-prev {
    transform: translateY(-50%) rotate(-225deg);
    left: 100px;
    @include r(1499) {
      left: 10px; }
    @include r(767) {
      left: 26px; } }

  .slick-dots {
    position: absolute;
    bottom: 46px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    max-width: 100%;
    width: auto;
    @include r(1499) {
      bottom: 60px; }
    li {
      width: 14px;
      height: 14px;
      cursor: pointer;
      font-size: 0;
      background: none;
      display: inline-block;
      margin: 0 8px;
      &:first-of-type {
        margin-left: 0; }
      &:last-of-type {
        margin-right: 0; }
      button {
        transition: all 0.2s ease-in-out;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        background: transparent;
        width: 16px;
        height: 16px;
        cursor: pointer; } }
    li.slick-active {
      button {
        background: #FFFFFF; } } } }
