
* {
	font-family: 'Source Sans Pro', sans-serif;
	line-height: 1;
	font-weight: 400; }


h1,h2,h3,h4,h5,h6 {
	color: $dark;
	font-weight: bold;
	margin: 0; }

h1,.h1 {
	font-size: 100px;
	line-height: 1.2em;
	margin-bottom: 1em;
	color: #fff;
	font-weight: 600;
	&:last-child {
		margin-bottom: 0; }
	@include r(1499) {
		font-size: 72px; }
	@include r(1023) {
		font-size: 36px; }
	@include r(767) {
		font-size: 24px; } }

h2, .h2 {
	font-size: 64px;
	line-height: 1.2em;
	margin-bottom: 1em;
	font-weight: 600;
	color: $dark;
	// text-transform: uppercase
	@include r(1023) {
		font-size: 28px; }
	@include r(767) {
		font-size: 24px; }
	&:last-child {
		margin-bottom: 0; } }


h3, .h3 {
	font-size: 48px;
	line-height: 1.2em;
	margin-bottom: 1em;
	font-weight: 700;
	font-style: italic;
	@include r(1499) {
		font-size: 36px; }
	@include r(1023) {
		font-size: 24px; }
	@include r(767) {
		font-size: 22px; }
	&:last-child {
		margin-bottom: 0; } }

h4, .h4 {
	font-size: 36px;
	line-height: 1.2em;
	margin-bottom: 1em;
	font-weight: 600;
	color: #fff;
	@include r(1499) {
		font-size: 36px; }
	@include r(1023) {
		font-size: 24px; }
	@include r(767) {
		font-size: 18px; }
	&:last-child {
		margin-bottom: 0; } }


p,
span {
	margin: 0;
	font-size: 24px;
	line-height: 1.9em;
	color: $dark;
	font-weight: 400;
	@include r(1499) {
		font-size: 18px;
		line-height: 1.45em; }
	@include r(767) {
		font-size: 14px;
		line-height: 1.5em; } }

span,
a {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit; }

a {
	text-decoration: none;
	color: inherit; }

li {
	margin-bottom: 1em;
	font-size: 24px;
	line-height: 1.4em;
	color: $dark;
	font-weight: 400;
	@include r(1499) {
		font-size: 18px;
		line-height: 1.4em; }
	@include r(767) {
		font-size: 14px; }
	&:last-child {
		margin-bottom: 0; } }

.typography {
	ul,ol {
		margin-bottom: 1em; }
	ol {
		list-style-type: decimal;
		padding-left: 16px;
		@include r(1023) {
			padding-left: 5px; } }
	ul {
		li {
			position: relative;
			padding-left: 30px;
			@include r(1023) {
				padding-left: 20px; }
			&::after {
				position: absolute;
				left: 0;
				top: 0.6em;
				content: '';
				width: 7px;
				height: 7px;
				background: $dark;
				border-radius: 50%;
				@include r(1499) {
					width: 5px;
					height: 5px; } } } }
	.alignright {
		float: right; }
	.alignleft {
		float: left; }
	img {
		max-width: 100%;
		display: block;
		height: auto;
		margin: 10px auto; }
	iframe {
		margin: 10px auto;
		display: block;
		max-width: 100%; }
	h2,
	h3,
	h4,
	h5,
	p {
		margin-bottom: 2em;
		line-height: 1.8em;
		@include r(1499) {
			line-height: 1.4em;
			margin-bottom: 1.6em; } }
	p {
		&:last-child {
					margin-bottom: 0; } } }
