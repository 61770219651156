.s_technical {
  padding: 60px 0 60px;
  &-box {
    background: $grad; }
  &__title {
    text-align: center;
    margin-bottom: 40px; }

  &__tabs {
    max-width: 735px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 40px; }
  &__tab {
    display: block;
    position: relative;
    @include r(1023) {
      transform: scale(0.8); }
    svg, img {
      opacity: 0.5;
      display: block;
      @include r(767) {
        margin: auto; } }
    &::after {
      content: '';
      position: absolute;
      height: 30px;
      background: #27AAE1;
      width: 1px;
      right: -24px;
      top: -1px; }
    &:last-of-type {
      &::after {
        display: none; } } }

  &__schemes {
    display: flex;
    align-items: center;
    justify-content: center; }

  &__scheme {
    display: none;
    // max-width: 1244px
    // width: 100%
    @include r(767) {
      display: block; }
    svg, img {
      width: 100%;
      object-fit: cover;
      height: auto;
      width: auto;
      max-height: calc(100vh - 100px);
      @include r(1025) {
        max-height: 100%; }
      @include r(767) {
        width: 100%; } } }



  &__desktop {
    display: block;
    @include r(1025) {
      display: none !important; } }
  &__mob {
    display: none;
    @include r(1025) {
      display: block !important; } }





  .show {
    display: block; }
  .active, .slick-current {
    border-bottom: 1px solid #FFFFFF;
    display: block;
    @include r(767) {
      border: none; }
    svg,img {
      opacity: 1; } }


  .slick-arrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
    display: inline-block;
    border: solid $white;
    border-width: 0 4px 4px 0;
    padding: 6px;
    font-size: 0;
    outline: none;
    z-index: 10;
    background: transparent;
    opacity: 0.5;
    &:hover {
      opacity: 1; }
    @include r(1499) {
      border-width: 0 3px 3px 0; }
    @include r(999) {
      padding: 4px; } }
  .slick-arrow.slick-next {
    right: 100px;
    transform: translateY(-50%) rotate(-45deg);
    @include r(1499) {
      right: 10px; }
    @include r(767) {
      right: 6px; } }

  .slick-arrow.slick-prev {
    transform: translateY(-50%) rotate(-225deg);
    left: 100px;
    @include r(1499) {
      left: 10px; }
    @include r(767) {
      left: 6px; } } }
