@mixin r($screen) {
  @media all and (max-width: $screen+'px') {
    @content; } }

@mixin b($screen) {
  @media all and (max-height: $screen+'px') {
    @content; } }

@mixin minw($screen) {
  @media all and (min-width: $screen+'px') {
    @content; } }

@function get-vw($target) {
  $vw-context: (1920*.01) * 1px;
  @return ($target/$vw-context) * 1vw; }

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot');
      src: font-url('#{$file-path}.eot?#iefix') format('embedded-opentype'), font-url('#{$file-path}.ttf') format('truetype'); }
    @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.ttf') format('truetype'); } } }
