.s_info {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 120px;
  padding-bottom: 87px;
  @include r(1499) {
    padding-top: 93px;
    padding-bottom: 86px; }
  @include r(1023) {
    padding-top: 60px;
    padding-bottom: 66px; }
  @include r(767) {
    padding-top: 47px;
    padding-bottom: 60px; }
  &::after {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: #112642;
    opacity: 0.9; }
  * {
    color: #fff; }
  .container {
    position: relative;
    z-index: 2; }
  &_wrapper {
    @include r(1365) {
      position: relative; } }
  &_slider {
    max-width: 100%;
    .s_info_name_arrow--back,
    .s_info_name_arrow--next {
      display: none!important;
      @include r(767) {
        display: block; } }
    * {
      outline: none; }
    .slick-arrow {
      position: absolute;
      border: none;
      cursor: pointer;
      outline: none;
      border: 2px solid #fff;
      border-width: 4px 0 0 4px;
      padding: 6px;
      font-size: 0;
      background: transparent;
      z-index: 10;
      transition: all 0.2s ease-in-out;
      display: none;
      @include r(1023) {
        border-width: 2px 0 0 2px;
        padding: 4px; }
      @include r(767) {
        display: block!important; }
      &:hover {
        border-color: $blue; } }
    .slick-arrow.slick-next {
      right: 90px;
      top: 50%;
      transform: translateY(-50%) rotate(135deg);
      right: 2px; }
    .slick-arrow.slick-prev {
      left: 90px;
      top: 50%;
      transform: translateY(-50%) rotate(315deg);
      left: 2px; } }
  &_slide {
    margin-bottom: 36px;
    position: relative;
 }    // min-height: 488px
  &_name {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 36px;
    width: 50.5%;
    position: relative;
    @include r(1199) {
      width: 100%; }
    @include r(767) {
      justify-content: center;
      padding: 0 30px;
      margin-bottom: 24px;
      h4 {
        font-weight: 400;
        margin: 0; } }
    img,
    svg {
      display: block;
      max-width: 100%;
      flex-shrink: 0;
      height: auto;
      margin-right: 24px;
      @include r(1023) {
        max-width: 60px; }
      @include r(767) {
        max-width: 50px; } } }
  &_img {
    position: absolute;
    right: 0;
    top: 0;
    width: get-vw(627px);
    width: 43.6%;
    @include r(1199) {
      width: 48%; }
    @include r(1023) {
      position: relative;
      right: unset;
      top: unset;
      max-width: 433px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 36px; }
    @include r(767) {
      margin-bottom: 24px; }
    img {
      display: block;
      max-width: 100%;
      height: auto;
      border-radius: 15px;
      border: 2px solid #fff; } }
  &_content {
    width: get-vw(724px);
    width: 50.5%;
    @include r(1199) {
      width: 48%; }
    @include r(1023) {
      width: 100%; } }
  &_text {
    p {
      font-size: 18px;
      margin-bottom: 1.25em;
      line-height: 1.3em;
      font-weight: 400;
      @include r(1499) {
        font-size: 16px; }
      @include r(767) {
        font-size: 14px; }
      &:last-child {
        margin-bottom: 0; } } }
  &_btn {
    margin-top: 60px;
    @include r(1499) {
      margin-top: 45px; }
    @include r(1023) {
      margin-top: 36px; } }
  &_tabs {
    overflow: hidden;
    @include r(767) {
      display: none; }
    // *
    //   outline: none
    // .slick-list
    //   margin: 0 -15px
    //   width: 100%
    //   +r(1499)
    //     margin: 0 -5px
    //   +r(1365)
    //     margin: -12px
    //
    // .slick-slide
    //   margin: 0 15px
    //   height: auto
    //   +r(1499)
    //     margin: 0 5px
    //   +r(1365)
    //     margin: 12px
    //   div
    //     +minw(1366)
    //       height: 100%
    //     .s_info_tab_wrap
    //       div
    //         +minw(1366)
    //           height: auto
    // .slick-track
    //   display: flex
    //   align-items: stretch
    //   justify-content: space-between
    // .slick-current.slick-active
    //   .s_info_tab
    //     &::after
    //       background: #27AAE1
    //       opacity: 0.5
    &_flex {
      margin: 0 -16px;
      align-items: stretch;
      flex-wrap: wrap;
      @include r(1365) {
        margin: -12px -74px; }
      @include r(1199) {
        margin: -12px -48px; }
      @include r(1023) {
        margin: -12px -11px; } } }
  &_tab {
    width: 12.5%;
    padding: 0 16px;
    min-height: 150px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // max-width: 150px
    @include r(1365) {
      padding: 12px 74px;
      width: 25%; }
    @include r(1199) {
      padding: 12px 48px; }
    @include r(1023) {
      padding: 12px 11px;
      // &:nth-child(4n)
      //   justify-content: flex-end
      // &:nth-child(4n+1)
 }      //   justify-content: flex-start
    // &::after
    //   content: ''
    //   display: block
    //   background: #D1F1FC
    //   border-radius: 5px
    //   opacity: 0.1
    //   z-index: -1
    //   width: 100%
    //   height: 100%
    //   position: absolute
    //   left: 0
    //   top: 0
    //   transition: all 0.2s ease-in-out
    // &:hover
    //   &::after
    //     background: #27AAE1
    //     opacity: 0.5

    &_wrap {
      position: relative;
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      z-index: 2;
      height: 100%;
      cursor: pointer;
      display: block;
      padding: 20px 4px 15px;
      max-width: 150px;
      min-height: 150px;
      &::after {
        content: '';
        display: block;
        background: #D1F1FC;
        border-radius: 5px;
        opacity: 0.1;
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.2s ease-in-out; }
      &:hover {
        &::after {
          background: #27AAE1;
          opacity: 0.5; } }
      p {
        font-size: 18px;
        margin-bottom: 1.25em;
        line-height: 1.3em;
        font-weight: 400;
        text-align: center;
        &:last-child {
          margin-bottom: 0; } } }
    &_wrap.active {
      &::after {
        background: #27AAE1;
        opacity: 0.5; } }
    &_img {
      min-height: 54px;
      margin-bottom: 10px;
      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto; } } }
  &_list {
    display: none;
    position: absolute;
    z-index: 10;
    left: -1px;
    top: 0;
    right: -1px;
    max-height: 100%;
    height: 100%;
    background: linear-gradient(81.33deg, #182E4C -2.21%, #1B3C63 25.67%, #20436D 68.27%, #142742 122.52%);
    padding: 36px 33px 70px 60px;
    @include r(1023) {
      padding-left: 24px;
      padding-right: 30px; }
    @include r(767) {
      padding-right: 10px;
      padding-left: 16px; }
    &_wrap {
      height: 100%; }
    .mCSB_scrollTools .mCSB_draggerRail {
      background: #56CCF2!important;
      width: 1px!important; }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      background: #fff!important;
      border-radius: 10px!important; }
    p {
      font-size: 18px;
      margin-bottom: 5px;
      line-height: 1.3em;
      font-weight: 400;
      @include r(1499) {
        font-size: 16px; } }
    &_close {
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
      display: block;
      margin-top: 15px;
      p {
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-bottom: 0px;
        &::after {
          background: #7FDBF9;
          content: '';
          display: block;
          position: absolute;
          left: -5%;
          top: 0;
          width: 110%;
          height: 1px; } }
      img,svg {
        margin: 0 auto 8px;
        display: block;
        max-width: 100%;
        height: auto;
        transform: rotate(180deg);
        animation: anim-arrow-back 1s infinite linear; } } }

  &_countries {
    columns: 4;
    column-gap: 44px;
    @include r(767) {
      columns: 3;
      column-gap: 30px; }
    @include r(639) {
      columns: 2;
      column-gap: 20px; }
    @include r(479) {
      columns: 1;
      text-align: center; } } }
