.s_popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 70;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  overflow-y: auto;
  @include r(1023) {
    background-color: $dark-blue; }
  &_layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  &_close {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: white;
      top: 40%;
      left: 0;
      transform: rotate(45deg);
      border-radius: 20px; }
    &::after {
      transform: rotate(-45deg); }

    &-mob {
      display: none;
      @include r(1023) {
        display: block;
        width: 30px;
        height: 30px;
        top: 27px;
        right: 21px;
        z-index: 10;
        &::before,
        &::after {
          height: 3px; } } }
    &-desk {
      @include r(1023) {
        display: none; } } }
  &_content {
    width: 100%;
    max-width: 415px;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $dark-blue;
    padding: 36px;
    border-radius: 15px; }

  &_contact {
    * {
      color: $white;

      p {
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0; } }
      a {
        transition: 0.2s;
        color: $blue;
        text-decoration: underline; } }

    &-title {
      text-align: center;
      margin-bottom: 36px; } } }
