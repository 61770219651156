

.animate_circls {
  position: absolute;
  top: 15%;
  left: 46%;
  transform: translate(-50%,-50%);
  height: 500px;
  width: 500px;
  z-index: 1;
  @include r(767) {
    height: 320px;
    width: 320px; } }


.animate_circls .wrap_circle {
  width: 100%;
  height: 100%;
  position: relative; }


.animate_circls .wrap_circle .circl_one {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  border: 3px solid #7FDBF9;
  filter: blur(2px);
  opacity: 0;
  animation: xsonar 3s cubic-bezier(0,.99,.5,1.02)  infinite 3s; }



.animate_circls .wrap_circle .circl_two {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  border: 3px solid #7FDBF9;
  filter: blur(2px);
  opacity: 0;
  animation: xsonar 3s cubic-bezier(0,.99,.5,1.02) infinite 0.7s; }



.animate_circls .wrap_circle .circl_three {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  border: 3px solid #7FDBF9;
  filter: blur(2px);
  opacity: 0;
  animation: xsonar 3s cubic-bezier(0,.99,.5,1.02) infinite; }



@keyframes xsonar {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 0; } }

