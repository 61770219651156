.s_screenshots {
  padding: 60px 0;
  @include r(767) {
    padding: 32px 0; }
  &__title {
    text-align: center;
    margin-bottom: 72px;
    @include r(1023) {
      margin-bottom: 40px; }
    h2 {
      color: $blue; } }

  &__gallery {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -16px; }
  &__item-wr {
    display: none;
    max-width: 11%;
    padding: 16px;
    width: 100%;
    position: relative;
    &:nth-of-type(-n+9) {
      display: block; }
    @include r(1499) {
      max-width: 16%;
      &:nth-of-type(-n+9) {
        display: none; }
      &:nth-of-type(-n+6) {
        display: block; } }
    @include r(1023) {
      max-width: 25%;
      &:nth-of-type(-n+9) {
        display: none; }
      &:nth-of-type(-n+6) {
        display: none; }
      &:nth-of-type(-n+4) {
        display: block; } }
    @include r(767) {
      max-width: 50%; }
    p {
      text-align: center;
      color: white;
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 1.2;
      min-height: 2.6em;
      max-height: 2em;
      overflow: hidden;
      @include r(1499) {
        font-size: 16px; }
      @include r(767) {
        margin-bottom: 8px;
        font-size: 14px; } } }
  &__item {
    overflow: hidden;
    width: 100%;
    display: block;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
      display: block;
      transition: 0.2s ease;
      height: 100px; }
    &:hover {
      img {
        transform: scale(1.2); } } }
  &__after {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(38, 67, 107, 0.38);
    transition: 0.2s ease;
    display: flex;
    align-items: center;
    text-align: center;
    img {
      transition: 0.2s ease;
      display: block;
      width: 30px;
      object-fit: cover;
      height: 30px;
      margin: auto; } }
  &__gallery-wr {
    display: none;
    margin-bottom: 36px; }
  &__name {
    margin-bottom: 44px;
    text-align: center;
    p {
      color: $white;
      span {
        color: inherit;
        text-decoration: underline; } }
    @include r(1023) {
      margin-bottom: 36px; } }
  &__more {
    display: block;
    text-align: center;
    cursor: pointer;
    // margin-top: 10px
    p {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      display: inline-block;
      position: relative;
      margin-bottom: 16px;

      &::after {
        background: #7FDBF9;
        content: '';
        display: block;
        position: absolute;
        left: -5%;
        bottom: -6px;
        width: 110%;
        height: 1px; } }
    img,svg {
      margin: 0 auto;
      display: block;
      max-width: 100%;
      height: auto;
      animation: anim-arrow 1s infinite linear; } }

  &__more.open {
    img,svg {
      transform: rotate(180deg);
      animation: anim-arrow-back 1s infinite linear; } }

  .show {
    display: block; }
  .active, .slick-current {
    border-bottom: 1px solid #FFFFFF;
    display: block;
    @include r(767) {
      border: none; }
    svg,img {
      opacity: 1; } }

  .slick-arrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
    display: inline-block;
    border: solid $white;
    border-width: 0 4px 4px 0;
    padding: 6px;
    font-size: 0;
    outline: none;
    z-index: 10;
    background: transparent;
    opacity: 0.5;
    &:hover {
      opacity: 1; }
    @include r(1499) {
      border-width: 0 3px 3px 0; }
    @include r(999) {
      padding: 4px; } }
  .slick-arrow.slick-next {
    right: 100px;
    transform: translateY(-50%) rotate(-45deg);
    @include r(1499) {
      right: 10px; }
    @include r(767) {
      right: 6px; } }

  .slick-arrow.slick-prev {
    transform: translateY(-50%) rotate(-225deg);
    left: 100px;
    @include r(1499) {
      left: 10px; }
    @include r(767) {
      left: 6px; } } }
