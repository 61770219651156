@import "button";

.s_about {
  padding-top: 120px;
  padding-bottom: 145px;
  background: #fff;
  @include r(1023) {
    padding-top: 60px;
    padding-bottom: 80px; }
  @include r(767) {
    padding-top: 48px;
    padding-bottom: 55px; }
  &_wrap {}
  &_heading {
    margin-bottom: 30px;
    @include r(1499) {
      margin-bottom: 15px; }
    @include r(1023) {
      margin-bottom: 27px; }
    @include r(767) {
      margin-bottom: 15px; } }
  &_icon {
    display: inline-block;
    background: url(../images/gx.png) no-repeat center center;
    background-size: cover;
    width: 124px;
    height: 45px;
    @include r(1023) {
      width: 54px;
      height: 20px; } }
  &_typo {
    max-width: 1113px; }
  &_btn {
    margin-top: 47px;
    @include r(1023) {
      margin-top: 35px; } } }
