.s_feedback {
  background: #fff;
  padding-top: 155px;
  padding-bottom: 150px;
  @include r(1023) {
    padding-bottom: 130px; }
  @include r(767) {
    padding-top: 135px;
    padding-bottom: 210px; }
  &_header {
    text-align: center;
    margin-bottom: 104px;
    @include r(1023) {
      margin-bottom: 40px; }
    h2 {
      display: inline-block;
      position: relative;
      z-index: 2;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      &::after {
        position: absolute;
        background: url(../images/quotes.png) no-repeat center center;
        background-size: cover;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 174px;
        height: 150px;
        z-index: -1;
        @include r(1023) {
          width: 73px;
          height: 62px; } } } }
  &_wrap {
    @include r(1499) {
      position: relative;
      padding: 0 36px; }
    @include r(1023) {
      padding: 0; } }
  &_slider {
    max-width: 100%;
    width: 100%;
    @include r(767) {
      padding: 0 40px; }
    .slick-slide {
      text-align: center; }
    .slick-arrow {
      position: absolute;
      border: none;
      cursor: pointer;
      outline: none;
      border: 2px solid #1E3759;
      border-width: 4px 0 0 4px;
      padding: 6px;
      font-size: 0;
      background: transparent;
      z-index: 10;
      transition: all 0.2s ease-in-out;
      @include r(1023) {
        border-width: 2px 0 0 2px;
        padding: 4px; }
      &:hover {
        border-color: $blue; } }
    .slick-arrow.slick-next {
      right: 90px;
      top: 50%;
      transform: translateY(-50%) rotate(135deg);
      @include r(1499) {
        right: 0; }
      @include r(1023) {
        right: -17px; }
      @include r(767) {
        right: 0; } }
    .slick-arrow.slick-prev {
      left: 90px;
      top: 50%;
      transform: translateY(-50%) rotate(315deg);
      @include r(1499) {
        left: 0; }
      @include r(1023) {
        left: -17px; }
      @include r(767) {
        left: 0; } } }
  &_slide {
    max-width: 620px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    @include r(1499) {
      max-width: 422px; }
    @include r(1023) {
      max-width: 395px; } } }
