.s_map {
  padding-top: 120px;
  padding-bottom: 137px;
  // overflow: hidden
  @include r(1023) {
    padding-top: 60px;
    padding-bottom: 75px; }
  @include r(639) {
    padding-top: 48px;
    padding-bottom: 54px; }
  &_overflow {
    overflow: hidden; }
  &_wrapper {
    position: relative; }
  &_wrap {
    min-height: 580px;
    margin-bottom: 27px;
    // overflow: hidden
    @include r(1799) {
      min-height: 560px; }
    @include r(1699) {
      min-height: 500px; }
    @include r(1599) {
      min-height: 470px; }
    @include r(1499) {
      min-height: 440px; }
    @include r(1199) {
      display: block;
      min-height: unset; }
    @include r(639) {
      margin-bottom: 35px; } }
  &_text {
    h2 {
      margin-bottom: 0px;
      color: $dark;
      @include r(1499) {
        margin-bottom: 25px; }
      @include r(1023) {
        margin-bottom: 15px; } }
    p {
      color: #1E3759;
      opacity: 0.8; } }
  &_content {
    width: 46.6%;
    @include r(1199) {
      max-width: 605px;
      width: 100%;
      margin-bottom: 30px; } }
  &_world {
    width: 51.5%;
    position: absolute;
    right: 0;
    top: 28px;
    @include r(1199) {
      position: static;
      width: 100%; }
    @include r(639) {
      display: none; } }
  &_img {
    padding-bottom: 58%;
    position: relative;
    @include r(1199) {
      padding: 0; }
    img,
    svg {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      @include r(1199) {
        position: static;
        width: auto;
        height: auto; }
      path {
        transition: all 0.2s ease-in-out; } } }
  &_select {
    text-align: center;
    position: relative;
    margin-bottom: 80px;
    @include r(1023) {
      margin-bottom: 40px; }
    &_info {
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      bottom: -35px;
      @include r(767) {
        bottom: -25px; }
      p {
        color: $blue;
        font-size: 18px;
        @include r(1023) {
          font-size: 16px; }
        @include r(767) {
          font-size: 14px; } } } }

  &_flex {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -20px get-vw(-70px);
    @include r(1499) {
      margin: -20px get-vw(-80px); }
    @include r(1365) {
      margin: -60px get-vw(-30px); }
    @include r(1023) {
      margin: -12px get-vw(-120px); }
    @include r(639) {
      margin: 0;
      display: block; } }
  &_item {
    width: 25%;
    padding: 20px get-vw(70px);
    @include r(1499) {
      width: 33.33333%;
      padding: 20px get-vw(80px); }
    @include r(1365) {
      padding: 60px get-vw(30px); }
    @include r(1023) {
      width: 50%;
      padding: 12px get-vw(120px); }
    @include r(639) {
      max-width: 280px;
      width: 100%;
      padding: 0;
      margin: 0 auto;
      margin-bottom: 24px;
      &:last-of-type {
        margin-bottom: 0; } }
    &_wrap {
      h4 {
        padding-left: 22px;
        color: $dark;
        margin-bottom: 11px;
        @include r(767) {
          padding-left: 14px; } }
      p {
        font-size: 18px;
        opacity: 0.85;
        @include r(767) {
          font-size: 14px; } }
      a {
        transition: all 0.2s ease-in-out;
        color: $blue;
        display: block;
        font-size: 18px;
        line-height: 1.9em;
        text-decoration: underline;
        @include r(767) {
          font-size: 14px; }
        &:hover {
          color: #213C61; } } }
    &_text {
      padding-left: 22px;
      @include r(767) {
        padding-left: 14px; } } }

  &_line {
    max-width: 100%;
    width: 100%;
    height: 2px;
    background: #27AAE1;
    opacity: 0.7;
    margin-bottom: 8px;
    @include r(1023) {
      width: 208px; }
    @include r(767) {
      margin-bottom: 10px; } } }
// .usa
//   fill: red
.europe {
  fill: #27AAE1; }
// .turkey
//   fill: blue
// .australia
//   fill: purple
// .zeland
//   fill: #dad629
// .africa
//   fill: black
// .uwa
//   fill: orange

.usa-marker {
  transform: translateY(37px); }
.uwa-marker {
  transform: translateY(-28px) translateX(32px); }
.item-marker {
  opacity: 0; }
.europe-marker {
  opacity: 1; }
