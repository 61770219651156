@import 'config/vars';


.site-button {
  font-size: 18px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  height: 43px;
  line-height: 43px;
  text-align: center;
  border-radius: 128px;
  background: $blue;
  display: inline-block;
  padding: 0 5px;
  min-width: 179px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  @include r(767) {
    min-width: 136px;
    font-size: 14px; }
  // &::after
  //   z-index: -1
  //   content: ''
  //   display: block
  //   position: absolute
  //   left: 0
  //   top: 100%
  //   width: 100%
  //   height: 0%
  //   background: $dark-blue
  //   transition: all 0.35s ease-in-out
  //   bottom: -100%
  &:hover {
    background: $dark-blue; }
  // &:active
  //   transform: none
  //   width: 0
  //   border-radius: 50%
  //   width: 43px
  //   min-width: 43px
  //   font-size: 0

  //// &::after
  ////   top: 0
  ////   height: 100%
 }  ////   transition: height 0.35s ease-in-out
