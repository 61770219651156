.s_comparison {
  padding: 50px 0;
  overflow: hidden;
  .container {
    @include r(767) {
      padding: 0; } }
  &__title {
    margin-bottom: 12px;
    padding-left: 60px;
    @include r(1499) {
      padding-left: 24px; }
    @include r(767) {
      padding-left: 16px; } }
  &__table {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      top: 3px;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid $white;
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
      @include r(767) {
        display: none; } } }
  &__table-wr {
    min-width: 320px;
    overflow: auto;
    table {
      border-spacing: 1px 5px;
      border-s {}
      width: 100%;
      td {
        text-align: center;
        padding: 7px;
        color: $blue;
        font-size: 18px;
        @include r(1023) {
          font-size: 14px; }
        @include r(767) {
          min-width: 155px; } }
      thead {
        border-collapse: collapse;
        margin-bottom: 6px;
        th {
          background: $blue;
          padding: 7px;
          font-weight: bold;
          @include r(767) {
            display: none;
            min-width: 220px;
            &:first-child {
              display: none; } } } }
      tbody {
        tr:nth-child(1) {

          td {
            background: #FFFFFF; } }

        tr:nth-child(2) {
          td {
            background: #FFFFFF;
            opacity: 0.8; } }
        tr:nth-child(3) {
          td {
            background: #FFFFFF; } }

        tr:nth-child(4) {
          td {
            background: #FFFFFF;
            opacity: 0.8; } }
        tr:nth-child(5) {
          td {
            background: #FFFFFF; } } } } }

  &__thFirst {
    text-align: left !important;
    padding: 7px 60px !important;
    max-width: 228px;
    color: #1E3759 !important;
    @include r(1499) {
      padding: 7px 24px !important; }
    @include r(767) {
      padding: 7px 16px !important;
      width: 160px; } }
  &__bottom {
    padding-left: 60px;
    @include r(1499) {
      padding-left: 24px;
      padding-right: 12px; }
    @include r(767) {
      padding-left: 16px;
      padding-bottom: 4px;
      border-bottom: 1px solid white; }
    p {
      color: $white;
      font-size: 18px;
      @include r(1023) {
        font-size: 14px; } } }

  &__thead {
    display: none;
    @include r(767) {
      display: flex;
      width: 100%;
      p {
        display: block;
        min-width: 156px;
        text-align: center;
        font-weight: bold;
        background: $blue;
        height: 35px;
        line-height: 35px;
        width: 100%; } } } }
