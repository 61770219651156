.s_quote {
  padding-top: 120px;
  padding-bottom: 150px;
  @include r(1499) {
    padding-bottom: 140px; }
  @include r(1023) {
    padding-top: 60px;
    padding-bottom: 84px; }
  @include r(767) {
    padding-top: 48px;
    padding-bottom: 60px; }
  &_wrap {
    max-width: 1274px;
    width: 100%;
    margin: 0 auto; }
  &_heading {
    text-align: center;
    margin-bottom: 30px;
    @include r(1499) {
      margin-bottom: 35px; }
    @include r(1023) {
      margin-bottom: 23px; }
    h3 {
      color: $blue; } }
  &_text {
    text-align: center; } }
