.s_footer {
  background: #122642;
  padding-top: 55px;
  padding-bottom: 55px;
  overflow: hidden;
  @include r(767) {
    padding-top: 36px;
    padding-bottom: 36px; }
  &_wrap {
    align-items: center;
    margin-bottom: 25px;
    @include r(1365) {
      display: block;
      margin-bottom: 26px; }
    @include r(767) {
      margin-bottom: 15px; } }
  &_logo {
    flex-shrink: 0;
    margin-right: 30px;
    display: block;
    @include r(1365) {
      margin: 0;
      margin-bottom: 36px; }
    @include r(767) {
      margin-bottom: 24px; }
    img {
      display: block;
      max-width: 100%;
      height: auto; } }
  &_items {
    justify-content: flex-end;
    @include r(1365) {
      justify-content: space-between; }
    @include r(1023) {
      flex-wrap: wrap;
      margin: -17px -32px; }
    @include r(767) {
      margin: -14px -32px; } }
  &_item {
    max-width: 260px;
    width: auto;
    margin-right: get-vw(130px);
    @include r(1365) {
      margin-right: get-vw(100px); }
    @include r(1023) {
      margin: 0;
      padding: 17px 32px; }
    @include r(767) {
      padding: 14px 32px; }
    @include r(639) {
      max-width: 100%; }
    &:last-child {
      margin-right: 0; }
    * {
      color: #fff; }
    &_wrap {
      p {
        font-size: 18px;
        line-height: 1.2em;
        @include r(767) {
          font-size: 14px; }
        a {
          color: $blue;
          &:hover {
            text-decoration: underline; } } } }
    &--contact {
      a {
        display: block;
        font-size: 18px;
        line-height: 1.2em;
        transition: all 0.2s ease-in-out;
        @include r(767) {
          font-size: 14px; }
        &:hover {
          color: $blue; } } } } }
