.s_features {
  background: #fff;
  padding-top: 120px;
  padding-bottom: 150px;
  background: linear-gradient(72.33deg, #182E4C -2.21%, #1B3C63 25.67%, #20436D 68.27%, #142742 122.52%);
  .container {
    @include r(1023) {
      padding: 0 30px; }
    @include r(767) {
      padding: 0 16px; } }
  @include r(1499) {
    padding-bottom: 134px; }
  @include r(1023) {
    padding-top: 60px;
    padding-bottom: 85px; }
  @include r(767) {
    padding-top: 50px;
    padding-bottom: 10px; }
  &_header {
    text-align: center;
    margin-bottom: 45px;
    @include r(1499) {
      margin-bottom: 55px; }
    @include r(1023) {
      margin-bottom: 40px; }
    @include r(767) {
      margin-bottom: 30px; } }
  &_wrap {
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
  &_item {
    width: 20%;
    border-right: 2px solid #2F6CB4;
    display: flex;
    align-items: center;
    // justify-content: center
    &:nth-child(5n+5) {
      border: none; }
    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3),
    &:nth-last-child(4),
    &:nth-last-child(5) {
      .s_features_item_wrap {
        padding-bottom: 0px; } }
    @include r(1499) {
      width: 33.333%;
      &:nth-child(5n+5) {
        border-right: 2px solid #2F6CB4; }
      &:nth-child(3n+3) {
        border: none; }
      &:nth-last-child(1),
      &:nth-last-child(2),
      &:nth-last-child(3), {
        .s_features_item_wrap {
          padding-bottom: 0px; } }
      &:nth-last-child(4),
      &:nth-last-child(5), {
        .s_features_item_wrap {
          padding-bottom: 30px; } } }
    @include r(767) {
      width: 100%;
      margin-bottom: 40px;
      border: none!important;
      display: none;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        display: block; }
      .s_features_item_wrap {
        padding: 0!important; } }
    &_wrap {
      justify-content: flex-start;
      padding: 14px get-vw(60px) 44px;
      align-items: center;
      @include r(1499) {
        padding-bottom: 30px; }
      @include r(1023) {
        padding-left: 15px;
        padding-right: 15px; }
      @include r(767) {
        max-width: 190px;
        width: 100%;
        margin: 0 auto; } } }
  &_img {
    width: 50px;
    flex-shrink: 0;
    margin-right: 15px;
    img,
    svg {
      display: block;
      max-width: 100%;
      height: auto; } }
  &_text {
    p {
      color: #fff;
      font-size: 18px;
      line-height: 1.4em;
      @include r(767) {
        font-size: 14px; } } }
  &_more {
    display: none;
    @include r(767) {
      display: block;
      text-align: center;
      // margin-top: 10px
      p {
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-bottom: 16px;
        &::after {
          background: #7FDBF9;
          content: '';
          display: block;
          position: absolute;
          left: -5%;
          bottom: -6px;
          width: 110%;
          height: 1px; } }
      img,svg {
        margin: 0 auto;
        display: block;
        max-width: 100%;
        height: auto;
        animation: anim-arrow 1s infinite linear; } } }
  &_more.open {
    img,svg {
      transform: rotate(180deg);
      animation: anim-arrow-back 1s infinite linear; } } }

@keyframes anim-arrow {
  0% {
    transform: translateY(0px); }
  33% {
    transform: translateY(-5px); }
  66% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0px); } }
@keyframes anim-arrow-back {
  0% {
    transform: rotate(180deg) translateY(0px); }
  33% {
    transform: rotate(180deg) translateY(-5px); }
  66% {
    transform: rotate(180deg) translateY(5px); }
  100% {
    transform: rotate(180deg) translateY(0px); } }
