// Add your settings here
@import 'config/vars';

.body-overflow {
  overflow: hidden; }

// .color-line
//   main
//     padding-bottom: 90px
//   footer
//     background: #fff
//     padding-top: 30px
main {
  position: relative;
  z-index: 2; }
footer {
  z-index: 1;
  position: relative; }
section.s_section {}



.s_section {
  padding: 120px 0 110px;
  @include r(1365) {
    padding: 100px 0; }
  @include r(999) {
    padding: 45px 0; } }




.container {
  max-width: 1920px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0 43px;
  // overflow-x: hidden
  // overflow-y: auto
  @include r(1499) {
    padding: 0 25px; }
  @include r(1023) {
    padding: 0 48px; }
  @include r(767) {
    padding: 0 16px; } }
.container--small {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  @include r(1499) {
    padding: 0 36px; }
  @include r(1023) {
    padding: 0; } }


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

button, a {
  outline: none; }


b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

ul,ol {
  list-style: none; }


.fw {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

label.error {
  display: none;
  position: absolute;
  bottom: 0px;
  right: 0;
  color: red;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  font-size: 12px;
  z-index: 5; }

.select-custom {
  display: inline-block; }
.custom-select-wrapper {
  position: relative;
  z-index: 2;
  display: inline-block;
  user-select: none;
  width: 619px;
  @include r(1023) {
    width: 360px; }
  @include r(639) {
    width: 280px; }
  select {
    display: none; } }

.custom-select {
  position: relative;
  display: inline-block;
  z-index: 5;
  // border-bottom: 1px solid $black
  // max-width: 292px
  width: 100%; }

.custom-select-trigger {
  position: relative;
  display: block;
  width: 100%;
  font-size: 64px;
  color: $dark;
  font-weight: 600;
  padding: 0px 20px 0px 8px;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: center;
  line-height: 1em;
  background: transparent;
  cursor: pointer;
  z-index: 2;
  @include r(1023) {
    font-size: 28px; }
  @include r(639) {
    font-size: 24px;
 }    // padding: 7px 20px 0px 8px
  &::before {
    position: absolute;
    left: 0;
    bottom: -7px;
    width: 100%;
    background: $blue;
    height: 2px;
    content: '';
    display: block; }


  &:after {
    position: absolute;
    content: '';
    display: block;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    // transform: translateY(-50%)
    // border: 5px solid transparent
    // border-top: 5px solid $black
    z-index: 1;
    width: 22px;
    height: 19px;
    background: url(../images/triangle.png) no-repeat center center;
    background-size: cover;
    @include r(1023) {
      width: 13px;
      height: 11px; } } }

.custom-select.opened .custom-select-trigger:after {
  transform: rotate(180deg) translateY(50%); }


.custom-options {
  position: absolute;
  padding-top: 29px;
  padding-bottom: 14px;
  display: block;
  top: calc( 100% + 6px);
  left: -1px;
  right: 0;
  min-width: calc( 100% + 2px);
  background: $blue;
  transition: all .4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  // transform: translateY(-15px)
  text-align: left; }
.custom-select.opened {}
.custom-select.opened .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0); }






.custom-option {
  position: relative;
  display: block;
  font-size: 36px;
  font-family: 'Source Sans Pro', sans-serif;
  color: $dark;
  font-weight: 600;
  padding: 0px 20px 0px 8px;
  display: block;
  cursor: pointer;
  transition: all .4s ease-in-out;
  margin-bottom: 30px;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0; }
  &:hover, &.selection {
    color: #fff; }
  @include r(1023) {
    font-size: 20px;
    margin-bottom: 20px; } }
