$height: 43px;
$trs-time: .4s;

.s_about_button{
  position: relative;
  width: 180px;
  @media all and (max-width: 767px){
    width: 136px;
  }
  a, p{
    line-height: 1.6em;
  }
  a{
    color: #738087;
  }

  .button{
    display: block;
    width: 180px;
    height: $height;
    line-height: $height;
    padding: 0;
    background: #27AAE1;
    margin: 0 auto;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    outline: none;
    text-align: center;
    border-radius: $height/2;
    transition: background $trs-time, font-size .05s, color 0.1s 0.5s, width $trs-time, border $trs-time, transform 0.2s;
    &:hover {
      transform: translateY(-2px);
    }
    @media all and (max-width: 767px){
      width: 136px;
      font-size: 14px;
    }
  }
  img{
    position: absolute;
    top: 50%;
    display: none;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  svg{
    transform: rotate(270deg) translateY(-50%);
    position: absolute;
    top: 0;
    left: 50%;
    display: none;
    .circle_2{
      stroke-dasharray: 0 200;
    }
    .fill_circle{
      animation: fill-stroke 2s .4s linear forwards;
    }
  }
  .circle{
    width: $height;
    // border: 3px solid #c3c3c3;
    background: #ddd8d7;
    color: transparent;
    transition: background $trs-time, font-size .05s, color 0s, width $trs-time, border $trs-time, transform 0.2s;
    &:hover{
      background: #ddd8d7;
      transform: translateY(0);
    }
  }
  .filled{
    background: #27AAE1;
    width: $height;
    color: transparent;
    line-height: $height;
    &:hover{
      transform: translateY(0);
    }
  }
}
// footer{
//   p{
//     color: #738087;
//     margin-top: 100px;
//     font-size: 18px;
//     line-height: 28px;
//   }
// }
@keyframes fill-stroke {
  0%{
    stroke-dasharray: 0 200;
  }
  20%{
    stroke-dasharray: 20 200;
  }
  40%{
    stroke-dasharray: 30 200;
  }
  50%{
    stroke-dasharray: 90 200;
  }
  70%{
    stroke-dasharray: 120 200;
  }
  90%{
    stroke-dasharray: 140 200;
  }
  100%{
    stroke-dasharray: 182 200;
  }
}
